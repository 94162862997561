import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { AppSettings } from './models/app-settings';
import { Controller } from 'cbfw-menu';
import { Constants } from './models/constants';
import { HtmlConstants } from './models/html-constants';
import { ConfigService } from './services/config.service';

import { CbfwFooterComponent } from 'cbfw-footer';
import { HeaderComponent } from 'cbfw-header';
import { Title } from '@angular/platform-browser';
import { CbfwContactUsComponent } from 'cbfw-contact-us';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  title = 'grip-insure-web';
  currentUrl: string;
  settings: AppSettings;
  headerConfig: any;
  footerConfig: any;
  contactUsSettings: any;
  corporateEntityId;
  appDevice: string;

  public notice: Controller = {
    IsVisible: Constants.showNotice,
    DisplayValue: HtmlConstants.noticeText
  };

  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  @ViewChild(CbfwFooterComponent) cbfwFooterComponent: CbfwFooterComponent;
  // @ViewChild(CbfwContactUsComponent) cbfwContactUsComponent: CbfwContactUsComponent;

  constructor(private configService: ConfigService, private titleService: Title) {
    this.settings = this.configService.config;
    this.headerConfig = this.settings.header;
    this.setCurrentYearInFooterConfig(this.settings.footer);
    this.footerConfig = this.settings.footer;
    this.footerConfig.connectWithUs.email = '';
    this.footerConfig.connectWithUs.phoneNumber = '';
    this.settings.header.phoneNumber = '';
    // this.contactUsSettings = this.settings.contactUs;
    // this.cbfwContactUsComponent.modalContactUsTitle.DisplayValue = '';
    this.titleService.setTitle(Constants.siteName);
  }

  ngAfterViewInit(): void {

    this.cbfwFooterComponent.modalTermsAndConditionsMessage.DisplayValue =
      HtmlConstants.modalTermsAndConditionsMessage;
    this.cbfwFooterComponent.modalContactUsMessage.DisplayValue = HtmlConstants.modalContactUsMessage;
    this.cbfwFooterComponent.modalPrivacyPolicyMessage.DisplayValue = HtmlConstants.modalPrivacyPolicyMessage;
    this.headerComponent.cbfwMenuComponent.menuItems = [];
    this.cbfwFooterComponent.quickLinksTitle.IsVisible = Constants.quicklinksTitleIsVisible;
    this.cbfwFooterComponent.quickLinksPrivacyPolicy.IsVisible = Constants.quickLinksPrivacyPolicyIsVisible;
    this.cbfwFooterComponent.quickLinksTermsConditions.IsVisible = Constants.quickLinksTermsConditionsIsVisible;
    this.cbfwFooterComponent.quickLinksContactUs.IsVisible = Constants.quickLinksContactUsIsVisible;
    // this.cdref.detectChanges();
  }

  private setCurrentYearInFooterConfig(footer: any): void{
    footer.copyrightStatement = footer.copyrightStatement.replace(
      '%currentYear%',
      new Date().getFullYear()
    );
  }
}
