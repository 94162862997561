import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HeaderModule } from 'cbfw-header';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ConfigService } from './services/config.service';
import { EnvConfig } from './services/app-env-config';
import { FooterModule } from 'cbfw-footer';
import { ContactUsModule } from 'cbfw-contact-us';
import { HeroBannerModule } from 'cbfw-hero-banner';


const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    HttpClientModule,
    FooterModule,
    ContactUsModule,
    HeroBannerModule
  ],
  providers: [
    { provide: EnvConfig, useFactory: () => EnvConfig.getInstance('../assets/env-config.json') },
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
