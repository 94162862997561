import { Controller } from 'cbfw-menu';

export class Constants {

    public static readonly siteName = 'Insured Gadgets';
    public static readonly clientPhoneNumber = '312-860-8082';
    public static readonly clientCallCenterPhoneNumber = '844-651-2322';
    public static readonly clientEmail = 'mywarranty@insuredgadgets.com';
    public static readonly areYouSureConfirmMessage = 'Are you sure you want to do this?';

    public static readonly homeRoute = '/home';
    public static readonly claimFilingRoute = '/claim/claimFiling';
    public static readonly policyLookupRoute = '/claim/policyLookup';
    public static readonly policyDetailsRoute = '/claim/policyDetails';
    public static readonly enrollmentEnrollRoute = '/enrollment/enroll';
    public static readonly enrollmentMobileDeviceRoute = '/enrollment/mobileDevice';
    public static readonly enrollmentDeviceTypeRoute = '/enrollment/deviceType';

    public static readonly appDeviceKey = 'app_device';

    public static readonly playStoreUrl =
        'https://play.google.com/store/apps/details?id=com.cellbrokerage.insuredgadgets';

    public static readonly appStoreUrl =
        'https://apps.apple.com/us/app/insured-gadgets/id1466581615?ls=1';


    // ------------hero banner-----------------------
    public static readonly heroBannerText = `To activate your free warranty, your product needs to be registered
     within 14 days of purchase. Registration can be completed in a few easy steps. If you have received a
     registration number with your product,
    click on the button below to start the registration process.`;

    // -------------Claim Process---------------------------

    public static readonly steperItemThreeLabel = 'Check Delivery';

    // -------claim-confirmation-componenet---------------------

    public static readonly policyLookupSearchItems = [
        {
            value: 'ESN',
            viewValue: 'IMEI/ESN/Serial number',
            errorMessage: `IMEI/ESN/Serial number is <strong>required</strong>`
        },
        {
            value: 'PolicyNumber',
            viewValue: 'Policy Number',
            errorMessage: `Policy Number is <strong>required</strong>`
        },
        {
            value: 'RegistrationNumber',
            viewValue: 'Registration Number',
            errorMessage: `Registration Number is <strong>required</strong>`
        }
    ];

    public static readonly policyLookupTextBoxDefaultValue = 'IMEI/ESN/Serial number';

    public static readonly claimConfirmationSuccessMessage =
        `Thank you for your submission. Your claim is being reviewed. Please allow 2 business days for a response.
If you have any questions, you may contact us at
 ` + Constants.clientCallCenterPhoneNumber + ` and reference your claim number`;

    public static readonly claimConfirmationFailMessage =
        `There was an error while processing your information. Please try again shortly.
     <br/> If the problem persists, please call us at ` + Constants.clientCallCenterPhoneNumber + ` for assistance.`;

    public static readonly claimDeductableReceiptText = 'Do you have a copy of your repair receipt?';

    public static readonly labelDeductibleReceiptText = `This reimbursement warranty program requires
     a receipt for repair or claims deductible payment in order to file claim.
      You will need to upload a copy of your receipt to proceed with your reimbursement request.`;

    // ------------claim-wizard-component-----------------

    public static readonly labelTitle = 'Check Delivery';

    public static readonly textBoxHowDeviceDamagedLabel = 'How did the device get damaged?';

    public static readonly modalCostOfRepairContentLabel = `The cost of repair / deductible paid is greater
     than the maximum reimbursement amount allowed under the program. If your
     claim is approved, you will receive the maximum amount.\n
     The maximum reimbursement amount is $`;

    public static readonly repairCostDecimalValue = true;

    public static readonly labelWhereDoYou = `Where do you want the check delivered to?`;

    public static readonly labelMaximuReimbursemenAmount = 'The maximum reimbursement amount is $';

    public static readonly labelYouMustEnter = `You must enter an email address
     and alternate contact number to proceed.`;

    public static readonly labelNote = `Note: Your benefit check will be mailed when
     the claim is approved. Approximate delivery time is within
    2-4 weeks.`;

    public static readonly reimbursementAmountErrorMessage =
        'Error occurred while getting Reimbursement Amount.';

    public static readonly maximumFileUploadSizeExceededErrorMessage =
        'Maximum file upload size is exceeded (Max File Size : 6MB)';

    public static readonly invalidFileType = 'Invalid file type';

    public static readonly invoiceComponenetLabelNote = `Note: A claim can only be filed if a device
    has been repaired or a replacement claim has been filed,
   thus it is mandatory to upload a repair receipt or a claim deductible receipt. `;

    public static readonly checkDeliveryLabelNoteDisplayValue =
        'Note: Your benefit check will be mailed when the claim is approved.';

    // --------------policy-lookup.component--------------
    public static readonly textBoxEmailLabel = 'Email used to register your screen protector';

    public static readonly radioButtonRegisteredProductLabel = 'Have you registered your Insured Gadgets product?';

    public static readonly labelRegisteredProduct = `
    In order to process a claim, your product must be registered within 14 days
    from the date of purchase and device must be free from damaged upon date of registration.
    Please click on Enroll at the top of the page to register.`;

    // -------------Enrollment Process---------------------------

    // ---------------Enrollment Wizard --------------------

    public static readonly enrollmentConfirmationFailMessage = `The policy creation failed due to an unknown error.
                                 Please try again shortly.   If the problem persists or for further
                                information, please call ` + Constants.clientCallCenterPhoneNumber + `.`;

    public static readonly enrollmentSummaryText = `If you need to file a warranty claim,
  please log into www.InsuredGadgets.com/Warranty and click the "File a Claim" link in the upper
  right hand corner.`;

    public static readonly deviceEnrollmentLabelText = `Other - Enrollment of devices other than mobile phones`;

    public static readonly enrollMobileDeviceDisplayMessage =
        `Enrollment of mobile phones are done through the Insured Gadgets application.
 Diagnostic procedures must be completed using the app. Please download the app to your mobile phone to begin.`;


    public static readonly regComponentPleaseContactMessage =
        `Please contact our customer service department for assistance at `
        + Constants.clientCallCenterPhoneNumber + '.';


    public static readonly validateRegistrateCodePleaseContactMsg =
        `The Guarantee Serial Number you entered has already been used.
          If you feel this is in error please contact our customer service department for assistance at `
        + Constants.clientCallCenterPhoneNumber + '.';

    public static readonly validateRegstrationCodeErrorMsg =
        `We are unable to locate the Registration number provided by you.
    Please check the Registration number and try again.`;

    public static readonly validateEsnConfirmationMsg =
        'IMEI / ESN / Serial Number is aready exist with an active policy';

    public static readonly noCarrierMsg =
        'No Carriers available for this model';

    public static readonly noProductMsg =
        'No product available';

    public static readonly noPlanMsg =
        'No Plans available';

    public static readonly enrollmentCancelConfirmationMsg =
        `You are about to cancel the enrollment process. If you proceed, already entered data will be discarded.
         Cancel the current enrollment process?`;

    public static readonly enrollmentConfirmationMessage =
        `Your registration request is under review. Your benefits are subject to the product terms and conditions.`;
    // ---------------------Common------------------------------------------

    public static readonly heroBannerHeader = 'Enrollment';

    public static readonly buttonHeroBanner = 'Click here to Enroll';

    public static readonly heroBannerButtonLinkedURL = '/enrollment/deviceType';

    public static readonly menuItems: Controller[] = [
        { DisplayValue: 'Home', LinkedUrl: '/home', IsVisible: false },
        { DisplayValue: 'Enroll', LinkedUrl: '/enrollment/deviceType', IsVisible: false },
        { DisplayValue: 'File a Claim', LinkedUrl: '/claim/policyLookup', IsVisible: false },
        { DisplayValue: 'FAQs', LinkedUrl: '/faq' }
    ];

    public static readonly modalSearchPolicyMessage = `
      Your search criteria returned the following policies.
      Please select the policy on which the claim should be filed on.`;

    public static readonly modalContactInfoMessage = `
      If you have any inqueries, please contact Customer Service at ` + Constants.clientCallCenterPhoneNumber;

    public static readonly quicklinksTitleIsVisible = false;

    public static readonly quickLinksPrivacyPolicyIsVisible = false;

    public static readonly quickLinksTermsConditionsIsVisible = false;

    public static readonly quickLinksContactUsIsVisible = false;

    public static readonly allowRouteToEnrollment = true;

    public static readonly heroBannerTextQuestionVisible = false;

    public static readonly heroBannerLinkFaqsVisisble = false;

    public static readonly heroBannerNoticeVisisble = false;

    public static readonly showCategoryListDropDownForIgr = true;

    public static readonly sessionProductCategoryVariableName = 'selectedCategoryId';

    // Added this on the User Story requirement 4973 | 2020-05-13
    public static readonly showImeiField = true;

    public static readonly showNotice = true;

    public static readonly policyLookuplNoticeVisisble = true;
}


